<template>
    <div>
        <ul class="list-singular">
            <li v-for="w in noun[gender]">
                <Abbreviation v-slot="{ word }" :v="w">
                    <Declension
                        v-if="gender === 'neutr' && config.nouns.declension"
                        :word="word"
                        tooltip
                    />
                    <Spelling v-else :text="word" />
                </Abbreviation>
            </li>
        </ul>
        <ul v-if="config.nouns.plurals" class="list-plural">
            <li v-for="w in noun[`${gender}Pl`]">
                <Abbreviation v-slot="{ word }" :v="w">
                    <Declension
                        v-if="gender === 'neutr' && config.nouns.declension"
                        :word="word"
                        plural
                        :singular-options="noun.neutr"
                        tooltip
                    />
                    <Spelling v-else :text="word" />
                </Abbreviation>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { genders, MinimalNoun } from '../src/classes.ts';

import useConfig from '../composables/useConfig.ts';

export default defineComponent({
    props: {
        noun: { required: true, type: Object as PropType<MinimalNoun> },
        gender: { required: true, type: String as PropType<typeof genders[number]> },
    },
    setup() {
        return {
            config: useConfig(),
        };
    },
});
</script>
