<template>
    <Table :data="templates" :columns="3" fixed>
        <template #header>
            <th v-for="gender in genders" :key="gender" class="text-nowrap">
                <NounGenderLabel :gender="gender" />
            </th>
            <th></th>
        </template>
        <template #row="{ el: template }">
            <td>
                <Noun :noun="template" gender="masc" />
            </td>
            <td>
                <Noun :noun="template" gender="fem" />
            </td>
            <td>
                <Noun :noun="template" gender="neutr" />
            </td>
            <th>
                <ul class="list-unstyled list-btn-concise">
                    <slot name="buttons" :template="template"></slot>
                </ul>
            </th>
        </template>

        <template #empty>
            <Icon v="search" />
            <T>nouns.empty</T>
        </template>
    </Table>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { genders, gendersWithNumerus } from '../src/classes.ts';
import type { MinimalNoun } from '../src/classes.ts';
import { nounTemplates } from '../src/data.ts';

export default defineComponent({
    props: {
        templateBase: { default: '', type: String },
        filter: { default: '', type: String },
    },
    data() {
        return {
            genders,
        };
    },
    computed: {
        templates(): MinimalNoun[] {
            return nounTemplates.filter((template) => {
                for (const field of gendersWithNumerus) {
                    for (const value of template[field]) {
                        if (value.toLowerCase().includes(this.filter)) {
                            return true;
                        }
                    }
                }
            }).map((template) => {
                return template.fill(this.templateBase || '-');
            });
        },
    },
});
</script>
