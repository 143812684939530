<template>
    <span>
        <template v-for="(meaning, abbr) in abbrs"><abbr v-if="meaning" :title="meaning">{{ abbr }}</abbr><span v-else>{{ abbr }}</span>&nbsp;</template><slot :abbrs="abbrs" :word="word"></slot>
    </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { abbreviations } from '../src/data.ts';

export default defineComponent({
    props: {
        v: { required: true, type: String },
    },
    computed: {
        dissectedWord(): { abbrs: Record<string, string | null>, word: string } {
            const abbrs: Record<string, string | null> = {};
            let word = this.v.trim();
            let m = null;
            while ((m = word.match(/^(\w+\.?) /)) !== null) {
                const abbr = m[1];
                abbrs[abbr] = abbreviations[abbr] || null;
                word = word.substring(abbr.length + 1).trim();
            }

            return { abbrs, word };
        },
        abbrs(): Record<string, string | null> {
            return this.dissectedWord.abbrs;
        },
        word(): string {
            return this.dissectedWord.word;
        },
    },
});
</script>

<style lang="scss" scoped>
@import "assets/variables";

abbr {
    text-decoration-color: $text-muted;
}
</style>
